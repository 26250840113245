@import 'assets/css/style.scss';

.appBar {
  top: $appBarHeight;
  width: calc(100% - #{$navigationWidth});
}

.toolbar {
  flex-wrap: wrap;
  align-items: center;
}

.title {
  margin-right: 4 * $spbSpacing;
}

.toolbarGrow {
  flex-grow: 1;
}

.toolbarNoGrow {
  flex-grow: 0;
}
