@import 'assets/css/style.scss';

.ringingIcon {
  color: $spbRinging;
}

.answeredIcon {
  color: $spbAnswered;
}

.missedIcon {
  color: $spbMissed;
}

.completedIcon {
  color: $spbCompleted;
}

.callInIcon {
  color: $spbCallIn;
}

.callOutIcon {
  color: $spbCallOut;
}

.callOutCompletedIcon {
  color: $concrete;
}

.callInCompletedIcon {
  color: $concrete;
}
