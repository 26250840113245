@import 'assets/css/style.scss';

.appFrame {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}

.mainContent {
  flex-grow: 1;
  overflow: auto;
  padding-top: 2 * $appBarHeight;
}
