@import 'assets/css/style.scss';

.selectedMessage {
  width: 100px;
  height: 24px;
  padding: 8px 6px;
  display: inline-block;
}

.container {
  position: relative;
}

.sideDrawerBackdrop {
  position: absolute;
  display: block;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.drawer {
  z-index: 3;
}
