@import 'assets/css/style.scss';

.background {
  background-color: $primary;
  height: 100vh;
}

.contentWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.message {
  color: $white;
  font-size: $font-extra-large;
}
