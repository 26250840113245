@import 'assets/css/style.scss';

.paper {
  margin: $spbSpacing;
  overflow: auto;
  max-width: 50%;
  padding: 3 * $spbSpacing;
}

.form {
  display: flex;
  flex-direction: column;
  padding: 2 * $spbSpacing 0;
}

.button {
  margin-top: $spbSpacing;
}
