@import 'assets/css/style.scss';

.background {
  background-color: $primary;
  height: 100vh;
}

.appName {
  color: $white;
  font-size: $font-extra-large;
}

.contentWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo {
  height: 60px;
  margin-right: 12px;
}

.halfName {
  font-weight: 300;
}

.copyright {
  font-size: 0.5em;
}

.loadingIndicator {
  background-color: $white;
}
