@import 'assets/css/style.scss';

.circle {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: $primary;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(15, 77, 157, 0.4);
  animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(15, 77, 157, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(15, 77, 157, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(15, 77, 157, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(15, 77, 157, 0.4);
    box-shadow: 0 0 0 0 rgba(15, 77, 157, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(15, 77, 157, 0);
    box-shadow: 0 0 0 10px rgba(15, 77, 157, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(15, 77, 157, 0);
    box-shadow: 0 0 0 0 rgba(15, 77, 157, 0);
  }
}
