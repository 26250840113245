.appBar {
  z-index: 1201;
}

.userMenu {
  margin-top: 40px;
  margin-left: 15px;
}

.topBar {
  flex: 1;
}

.logo {
  margin-right: 8px;
  height: 48px;
}

.halfName {
  font-weight: 300;
}

.copyright {
  font-size: 0.8rem;
}
