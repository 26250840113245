@import 'assets/css/style.scss';

.wrapper {
  position: relative;
  margin: 8px 0 4px;
}

.flexGrow {
  flex-grow: 1;
}

.label {
  position: absolute;
  padding: 0px 5px;
  background: $white;
}

.input {
  padding: 10px 14px 10px 58px !important;
  width: 100% !important;
}

.fieldsContainer {
  display: flex;
  align-items: center;
}

.selectOverride {
  padding: 8px 32px 9px 8px;
}

.formControl {
  margin-left: $spbSpacing;
}
