@import 'assets/css/style.scss';

.paper {
  margin: $spbSpacing;
}

.tableBody {
  cursor: pointer;
}

.labelCell {
  padding: 5px 10px;
  background-color: #fafafa;
  top: 58px;
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
}

.labelCellSpan {
  font-size: $font-small;
  font-weight: 400;
  opacity: 0.7;
}

.tableContainer {
  max-height: 770px;
}
