@import 'assets/css/style.scss';

.drawer {
  width: $navigationWidth;
  flex-shrink: 0;
}

.drawerPaper {
  width: $navigationWidth;
  padding-top: $appBarHeight;
}

.drawerContent {
  overflow: auto;
}

.versionContainer {
  color: $disabled;
  position: inherit;
  left: 160px;
  bottom: 0;
}
