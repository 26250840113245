@import 'assets/css/style.scss';

.paper {
  margin: $spbSpacing;
  width: $sidePanelWidth;
  min-height: 250px;
  max-height: calc(100vh - 2px - #{2 * $appBarHeight} - #{2 * $spbSpacing});
  position: sticky;
  overflow: auto;
  padding: 2 * $spbSpacing;
}

.leftFloat {
  float: left;
}

.rightFloat {
  float: right;
}

.autoOverflowY {
  overflow-y: auto;
}

.scrollOverflowY {
  overflow-y: scroll;
}

.hiddenOverflowY {
  overflow-y: hidden;
}
