// Theme Global Sizes
$spbSpacing: 8px;

// Block dimensions
$appBarHeight: 64px;
$navigationWidth: 240px;
$sidePanelWidth: 450px;

// colors
$primary: #0d4f9d;
$white: #ffffff;
$secondary: #ff1744;
$disabled: #bdbdbd;
$error-color: #ff1744;
$success-color: #28a745;

$spbRinging: #1f7ce8;
$spbAnswered: #696969;
$spbMissed: #f71313;
$spbCompleted: #51a351;
$spbCallIn: #ff8100;
$spbCallOut: #696969;

// font-sizes:
$font-extra-large: 48px;
$font-large: 24px;
$font-medium: 18px;
$font-small: 12px;

$concrete: #696969

:export {
  primary: $primary;
  secondary: $secondary;
}
