.autocompleteOption {
  display: flex;
  height: 100%;
  width: 100%;
  flex-grow: 1;
}

.popperDisablePortal {
  position: absolute;
}

.wrapper {
  position: relative;
}
