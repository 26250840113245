@import 'assets/css/style.scss';

.accordionRoot {
  background-color: initial;
  max-width: 200px;
}

.accordionSummaryRoot {
  min-height: 20px;
  padding: 0;
}

.accordionSummaryContent {
  margin: 0;
}

.accordionSummaryExpanded {
  margin: 0 !important;
  min-height: 20px !important;
}

.accordionSummaryExpandIcon {
  padding: 0;
}

.accordionDetailsRoot {
  padding: 0;
  display: block;
}

.phoneItem {
  display: flex;
  flex: 1;
  align-items: flex-end;
  padding: 2px;
}

.highlight {
  color: $secondary;
}
